import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import LocationsSection from 'components/locations-section'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const InspectionsLandingPage = props => {
  const {
    data: {
      heroImage,
      techCutoutImage,
      agInspectionsImage,
      cceInspectionsImage,
      mowerInspectionsImage,
    },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Inspection & Service Programs | Hutson Inc</title>
      </Helmet>

      <Hero
        title='Inspection & Service Programs'
        image={getImage(heroImage)}
        overlayOpacity={0.25}
      />

      <MainSection>
        <div className='column'>
          <Content kind='full'>
            <H2>Ultimate uptime summer service programs</H2>
            <P>
              Keep your equipment running this summer with an ultimate uptime inspection from
              Hutson! Whether you have a business to run or just want to mow your lawn, we have a
              deal for you.
            </P>
            <P>
              Browse our digital service catalogs below then get in touch with your nearest Hutson
              location to schedule or get a quote.
            </P>
            <div>
              <Button as={ButtonLink} color='green' ghost to='/locations/'>
                Find your nearest store
              </Button>
            </div>
          </Content>
        </div>
        <div className='column'>
          <div className='tech-image-container'>
            <GatsbyImage
              image={getImage(techCutoutImage)}
              objectFit='contain'
              objectPosition='center bottom'
              style={{ width: '100%' }}
              alt='Hutson Inc technician'
            />
          </div>
        </div>
      </MainSection>
      <FlexGrid reverse>
        <div>
          <GatsbyImage
            image={getImage(agInspectionsImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt=''
          />
        </div>
        <FlexGridPrimarySectionContent>
          <SectionLabel>Inspection</SectionLabel>
          <H3 as='h2'>Ag Equipment Inspection Programs</H3>
          <P>
            See offers inside, including $199 planter inspections, 50% off or FREE inspection with
            qualifying repairs, up to 25% off planter repair parts, and 180 days No Payments/No
            Interest plans.
          </P>
          <P>Click the link for your state below to see complete details.</P>
          <div>
            <Button
              as={ButtonA}
              color='green'
              ghost
              href='https://www.flipsnack.com/5975ABDD75E/aftermarket-summer-mailer-2024-michigan-cog/full-view.html'
            >
              View MI Programs
            </Button>
            <Button
              as={ButtonA}
              color='green'
              ghost
              href='https://www.flipsnack.com/5975ABDD75E/aftermarket-summer-mailer-2024-south-cog/full-view.html'
            >
              View KY, TN, IN, IL & MO Programs
            </Button>
          </div>
        </FlexGridPrimarySectionContent>
      </FlexGrid>
      <FlexGrid>
        <div>
          <GatsbyImage
            image={getImage(mowerInspectionsImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt=''
          />
        </div>
        <FlexGridReverseSectionContent>
          <SectionLabel>Service</SectionLabel>
          <H3 as='h2'>Mower Service Program</H3>
          <P>
            Gear up for the rest of mowing season with a fresh service! All makes and models are
            eligible, including residential and commercial riding mowers, zero-turns, stand-ons, and
            walk-behinds.
          </P>
          <div>
            <Button
              as={ButtonA}
              color='yellow'
              href='https://www.flipsnack.com/5975ABDD75E/2024-turf-inspection-program/full-view.html'
            >
              View Program Details
            </Button>
          </div>
        </FlexGridReverseSectionContent>
      </FlexGrid>
      <FlexGrid reverse>
        <div>
          <GatsbyImage
            image={getImage(cceInspectionsImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt=''
          />
        </div>
        <FlexGridPrimarySectionContent>
          <SectionLabel>Service + Inspection</SectionLabel>
          <H3 as='h2'>Compact Construction Equipment Service & Inspection Program</H3>
          <P>
            Keep your work on track with a service and inspection for your skid steer, compact track
            loader, or mini excavator.
          </P>
          <div>
            <Button
              as={ButtonA}
              color='green'
              ghost
              href='https://www.flipsnack.com/5975ABDD75E/2024-cce-inspection-program/full-view.html'
            >
              View Program Details
            </Button>
          </div>
        </FlexGridPrimarySectionContent>
      </FlexGrid>
      <LocationsSection />
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 600px) {
    height: 350px;
  }

  @media (min-width: 900px) {
    height: 450px;
  }
`

const MainSection = styled.div`
  position: relative;

  .column {
    &:first-child {
      z-index: 1;
      position: relative;
    }

    &:last-child {
      bottom: 0;
      position: absolute;
      width: 200px;
      opacity: 0.15;
      right: 24px;
    }
  }
  /* 
  img {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
  } */

  @media (min-width: 900px) {
    ${clearfix}
    display: flex;

    .column {
      &:first-child {
        ${column('9/12')}
      }

      &:last-child {
        ${column('3/12')}
        position: relative;
        overflow: hidden;
        opacity: 1;
      }
    }

    .tech-image-container {
      display: flex;
      height: 100%;
      left: 0;
      padding: 36px 36px 0 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    /* img {
      mask-image: none;
    } */
  }
`

const SectionLabel = styled.span`
  color: ${props => props.theme.color.g400};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`

const FlexGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

    > * {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }
`

const FlexGridTextColumn = styled.div`
  padding: 36px 24px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
  }
`

const FlexGridReverseSectionContent = styled(FlexGridTextColumn)`
  background-color: ${props => props.theme.color.g400};

  ${SectionLabel} {
    color: ${props => props.theme.color.y400};
  }

  h2 {
    color: #fff;
  }

  p {
    color: ${props => props.theme.color.g50};
  }
`

const FlexGridPrimarySectionContent = styled(FlexGridTextColumn)`
  background-color: ${props => props.theme.color.n20};
`

const ButtonA = styled(OutboundLink)`
  display: inline-block;
  margin: 8px 8px 8px 0;
  text-decoration: none;
`

const ButtonLink = styled(Link)`
  display: inline-block;
  margin: 8px 8px 8px 0;
  text-decoration: none;
`

export const pageQuery = graphql`
  query InspectionsLandingPageQuery {
    heroImage: file(relativePath: { eq: "landing-pages/inspections/inspections-hero.jpg" }) {
      ...FullWidthImage
    }
    techCutoutImage: file(relativePath: { eq: "technician-cutout.jpg" }) {
      ...SharpImage480
    }
    agInspectionsImage: file(relativePath: { eq: "landing-pages/inspections/ag-inspections.jpg" }) {
      ...SharpImage900
    }
    cceInspectionsImage: file(
      relativePath: { eq: "landing-pages/inspections/cce-inspections.jpg" }
    ) {
      ...SharpImage900
    }
    mowerInspectionsImage: file(
      relativePath: { eq: "landing-pages/inspections/mower-inspections.jpg" }
    ) {
      ...SharpImage900
    }
  }
`

export default InspectionsLandingPage
